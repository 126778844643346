@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: Inter,-apple-system,BlinkMacSystemFont,Helvetica Neue,Arial,sans-serif;
  }

  body {
      @apply bg-dark;
      @apply text-gray;
      @apply overflow-x-hidden;

  }
}

@layer components {
    .text-color {
        color: @apply gray;
    }

    .bg-color {
        background-color: @apply dark;
    }

    .title-color {
        @apply text-white;
    }

    .transition {
        transition: all .2s ease-in-out;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    .overflow-fade {
    -webkit-mask-image: linear-gradient(90deg, #202022 90%, transparent);
    }

    .nav-bg {
        @apply bg-dark;
    }
}